
















































































































import useContact from "@/use/contact";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    OUserInfo: () => import("@/components/organisms/o-user-info.vue"),
    OUserInfoDriver: () =>
      import("@/components/organisms/o-user-info-driver.vue"),
    OVehiclesList: () => import("@/components/organisms/o-vehicles-list.vue"),
    OVehiclesCarousel: () =>
      import("@/components/organisms/o-vehicles-carousel.vue"),
    OFuelCardTransactionsList: () =>
      import("@/components/organisms/o-fuel-card-transactions-list.vue"),
    MChartSavings: () => import("@/components/molecules/m-chart-savings.vue"),
    MChartBestDrivers: () =>
      import("@/components/molecules/m-chart-best-drivers.vue"),
    MChartMileages: () => import("@/components/molecules/m-chart-mileages.vue"),
    OFleetPolicy: () => import("@/components/organisms/o-fleet-policy.vue"),
    OPoolVehicles: () => import("@/components/organisms/o-pool-vehicles.vue"),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const that = vm as any;
      that.lastVehicle = null;
      if (from.name === "panel.vehicle.view")
        that.lastVehicle = from.params.id || null;
    });
  },

  setup(props, { root }) {
    const { hasAccessTo } = useUser({ root });

    const lastVehicle = ref<string | null>(null);

    const state = reactive({
      loading: false,
      loadingValue: 0,
      user: computed(() => root.$store.getters["user/getData"]),
      companies: computed(() => root.$store.getters["user/getCompanies"]),
      vehicleCount: null,
      tab: 0,
    });

    const { getUnread, hasUnread } = useContact({ root });

    onMounted(() => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      if (hasAccessTo.value("customer")) {
        state.loading = true;

        axiosInstance
          .get("company/current/vehicle/count")
          .then(({ data }) => (state.vehicleCount = data))
          .catch(() => (state.vehicleCount = null))
          .finally(() => {
            state.loading = false;
            setTimeout(() => (state.loadingValue = 100), 50);
          });
      }
    });

    const preview = reactive({
      loading: false,
      disabled: true,
      isOpen: false,
      id: null as any,
    });

    const showFleetPolicy = () => (preview.isOpen = true);

    const fetchFleetPolicy = () => {
      if (state.companies && state.companies.length) {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;
        preview.loading = true;

        axiosInstance
          .get("fleet-policy/current")
          .then(({ data: { fleetPolicy } }) => {
            preview.isOpen = false;
            preview.id = fleetPolicy.map(({ id }: { id: string }) => id);

            setTimeout(() => (preview.disabled = false), 1000);
          })
          .catch(() => {
            preview.disabled = true;
            preview.isOpen = false;
            preview.id = null;
          })
          .finally(() => {
            setTimeout(() => (preview.loading = false), 1000);
          });
      }
    };

    onMounted(fetchFleetPolicy);
    watch(() => state.companies, fetchFleetPolicy);

    return {
      state,
      getUnread,
      hasUnread,
      hasAccessTo,
      lastVehicle,
      preview,
      showFleetPolicy,
    };
  },
});
